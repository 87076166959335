
import { createRouter, createWebHashHistory } from 'vue-router'
import { i18n } from '../main'

const routes = [

   

   {
        name:'HomePage',
        path:'',
        component:() => import('@/views/HomePage'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    
    {
        name:'PropertyManagement',
        path:'/property-management',
        component: () => import('@/views/PropertyManagement'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'HkServices',
        path:'/services',
        component: () => import('@/views/HkServices'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'AfricaPage',
        path:'/africa',
        component: () => import('@/views/AfricaPage'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'africa',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'NewsBlogs',
        path:'/news-blogs',
        component: () => import('@/views/about-us/NewsBlogs'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'HkNews',
        path:'/news-blogs/news',
        component: () => import('@/views/about-us/HkNews'),
        meta: {
            darkMode: 'HK-Black',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'HkBlogs',
        path:'/news-blogs/blogs',
        component: () => import('@/views/about-us/HkBlogs'),
        meta: {
            darkMode: 'HK-Black',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    
    {
        name:"IsmsPolicy",
        path:'/isms-policy',
        component: () => import('@/views/about-us/IsmsPolicy'),
        meta: {
            darkMode: 'HK-Black',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
   

    {
        name:'HkTestimonials',
        path:'/testimonials',
        component: () => import('@/views/about-us/HkTestimonials'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'JobDetails',
        path:'/job-details',
        component:()=> import('@/views/about-us/JobDetails'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'BlogDetails',
        path:'/blog-details',
        component:()=> import('@/views/about-us/BlogDetails'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'IndependentNews',
        path:'/independent-news',
        component:()=> import('@/views/about-us/IndNews'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'EnterpriseNews',
        path:'/enterprise-news',
        component:()=> import('@/views/about-us/EnterpriseNews'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
        },
        {
            name:'ContactSupports',
            path:'/support',
            component: () => import('@/views/contacts/ContactSupports'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'mob-white',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
    
        },
        {
            name:'HkCompany',
            path:'/company',
            component: () => import('@/views/about-us/HkCompany'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'mob-white',
                description:'',
                img_url:'',
                keywords:'',
                title:''
              },
        },
        {
            name:'ContactUs',
            path:'/contact',
            component: () => import('@/views/contacts/ContactUs'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'FrontDesk',
            path:'/front-desk',
            component: () => import('@/views/pms/FrontDesk'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
    
        {
            name:'CorporateRatesBilling',
            path:'/corporate-rates-billing',
            component: () => import('@/views/pms/CorporateRatesBilling'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'GroupsManagement',
            path:'/groups-management',
            component: () => import('@/views/pms/GroupsManagement'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'HkReports',
            path:'/reports',
            component: () => import('@/views/pms/HkReports'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'HousekeepingMaintenance',
            path:'/housekeeping-maintenance',
            component: () => import('@/views/pms/HousekeepingMaintenance'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'PaymentsAutomation',
            path:'/payments-automation',
            component: () => import('@/views/pms/PaymentsAutomation'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'PropertyAdministration',
            path:'/property-administration',
            component: () => import('@/views/pms/PropertyAdministration'),
            meta: {
                darkMode: 'HK-White',
                mobileMode:'mob-product',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
        {
            name:'TheEnterprise',
            path:'/enterprise',
            component: () => import('@/views/TheEnterprise'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'mob-white',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
    
        {
            name:'RequestADemo',
            path:'/contact-team',
            component: () => import('@/views/contacts/RequestADemo'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'mob-white',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
                
              },
        },
        {
            name:'HkCareers',
            path:'/careers',
            component: () => import('@/views/about-us/HkCareers'),
            meta: {
                darkMode: 'HK-Black',
                mobileMode:'mob-white',
                // description:'',
                // img_url:'',
                // keywords:'',
                title:''
              },
        },
    
 // products
 {
    name:'PropertyManagementSystem',
    path:'/property-management-system',
    component: () => import('@/views/pms/PropertyManagementSystem'),
    meta: {
        darkMode: 'HK-White',
        mobileMode:'mob-product',
        // description:'A user-intuitive, customizable property management platform with efficient workflows, automated reporting, and increased guest satisfaction.',
        // img_url:'frontdesk.webp',
        // keywords:'Property Management System, PMS, Hotel Management Software, Hospitality Software, Property Software, Hotel PMS, Hospitality Management System, Hotel Operations Software, Property Operations System, Front Desk Software, Reservation System, Booking Management Software, Hotel Administration Software, Property Automation System, Guest Management Software, Revenue Management System, Housekeeping Management Software, Hospitality Technology, Hotel Software Solutions, Property Administration Platform, Integrated Hotel Management System, Room Inventory Management, Hotel Front Office System, Hospitality Property Software, Property Revenue Optimization, Cloud-Based Property Management System for Hotels, Hotel Property Management System with Channel Manager Integration, System with Channel Manager Integration, Integrated PMS and CRM Solution for Hotel Chains, Modern Property Automation System, Centralized Hotel Operations Management Software with Multi-Property Support, User-Friendly Property Management System for Bed and Breakfasts',
        title:'HotelKey: Property Management System'
      },
      
},
{
    name:'RateManagementSystem',
    path:'/rate-management-system',
    component: () => import('@/views/rms/RateManagementSystem'),
    meta: {
        darkMode: 'HK-White',
        mobileMode:'mob-white',
        // description:'Quickly manage and distribute your rates and inventory all in one place. Automated STR controls, restriction and policy management, auto adjustable pricing and more.',
        // img_url:'rms_banner.svg',
        // keywords:'Rate Management System, RMS, Pricing Software, Revenue Management Tool, Rate Optimization Platform, Rate Control System, Pricing Strategy Software, Rate Setting Tool, Dynamic Pricing System, Revenue Optimization Software, Rate Management System for Hotels and Resorts, Rate Setting, Revenue Tool, Pricing Management, Rate Strategy, Revenue Platform, Pricing System, Rate Analysis, Revenue Control, Cloud-Based Rate Optimization Platform for Vacation Rental Owners, Revenue Management Software with Automated Pricing Recommendations, Pricing Software for Hospitality Businesses with Channel Integration, Rate Setting Tool with Historical Data Analysis Features, Dynamic Rate Management System for Maximizing Profitability, Integrated Revenue Management Solution for Hotel Operations, Pricing Strategy Software for Small and Medium-Sized Hotels',
        title:'HotelKey: Rate Management System'
      },

},
{
    name:'CentralReservationSystem',
    path:'/central-reservation-system',
    component: () => import('@/views/crs/CentralReservationSystem'),
    meta: {
        darkMode: 'HK-White',
        mobileMode:'mob-white',
        // description:'Experience seamless room distribution across all major OTAs and GDS platforms. Say goodbye to overbookings with real-time inventory sync and comprehensive distribution management tools.',
        // img_url:'distribution.svg',
        // keywords:'Central Reservation System, CRS, Reservation Software, Booking System, Reservation Platform, Hotel Booking Software, Central Booking System, Reservation Management, Hotel Reservation System, Booking Management, Comprehensive Central Reservation System for Hotels and Resorts, Cloud-Based Reservation Software for Hospitality, Integrated Booking Platform with Channel Management Integration, Automated Hotel Reservation System with Real-Time Availability, Centralized Booking Management System for Vacation Rentals, Advanced Reservation Management Software for Independent Hotels, Central Reservation System with Multi-Property Support, User-Friendly Hotel Booking Software with Mobile Compatibility, Reservation Platform with Group Booking, Management Features, Cloud-Based Central Booking System for Bed and Breakfasts, Hotel Reservation Software with Dynamic Pricing',
        title:'HotelKey: Central Reservation System'
      },

},
// crs menus
{
    name:'BookingEngine',
    path:'/booking-engine',
    component: () => import('@/views/crs/BookingEngine'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'HkDistribution',
    path:'/distribution',
    component: () => import('@/views/crs/HkDistribution'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
    },

},
{
    name:'PropertyWebsite',
    path:'/property-website',
    component: () => import('@/views/crs/PropertyWebsite'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'RateCalendar',
    path:'/rate-calendar',
    component: () => import('@/views/crs/RateCalendar'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},

{
    name:'RateAdmin',
    path:'/rate-room-type-admin',
    component: () => import('@/views/crs/RateAdmin'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},

{
    name:'AutomatedIntegration',
    path:'/automated-str-integration',
    component: () => import('@/views/rms/AutomatedIntegration'),
    meta: {
        darkMode: 'HK-White',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'HkIntegrations',
    path:'/integrations',
    component: () => import('@/views/integrations/HkIntegrations'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'Choose from 100+ APIs to connect with HotelKey products. Eliminate operational glitches. Streamline workflows like a pro.',
        // img_url:'integrations-banner.svg',
        // keywords:'Hotel Integration Partners, Hospitality Integrations, Hotel API Partners, Property Management Integrations, Hotel Software Partners, Integration Solutions for Hotels, Hospitality Technology Partners, Hotel System Integrations, Property Management System Partners, Hotel Tech Integrations, Hotel API Integration Solutions for Seamless Operations, Hospitality Software Integration Partnerships for Improved Efficiency, Hotel Technology Partnerships for Enhanced Guest Experience, Property Management System Integration Solutions for Hotel Chains, Integrated Technology Solutions for Hotel Operations Management, Hotel Software Integration Partners for Streamlined Front Office Operations, Seamless Integration Solutions for Hotel Booking Systems, Hospitality Technology Partnerships for Revenue Optimization, Property Management System Integration Partners for Enhanced Guest Service, Hospitality Integration Services, Hotel Tech Partners, Property Integration Partners, Hotel API Integration, Hospitality Software Integrations, Hotel System Partners, Property Tech Integrations, Hotel Technology Partners',
        title:'HotelKey Integrations: 100+ Options. One Single Platform'
      },
},
{
    name:'CompShopData',
    path:'/comp-shop-data',
    component: () => import('@/views/rms/CompShopData'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'DynamicBarhk',
    path:'/dynamicbar',
    component: () => import('@/views/rms/DynamicBarhk'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'NotificationsRules',
    path:'/notifications-rules',
    component: () => import('@/views/rms/NotificationsRules'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'PolicyManagement',
    path:'/policy-management',
    component: () => import('@/views/rms/PolicyManagement'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'HkSeasons',
    path:'/seasons',
    component: () => import('@/views/rms/HkSeasons'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'SpecialEvents',
    path:'/special-events',
    component: () => import('@/views/rms/SpecialEvents'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'AdvancedRestriction',
    path:'/advanced-restriction-controls',
    component: () => import('@/views/rms/AdvancedRestriction'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
// Bi
{
    name:'BusinessIntelligence',
    path:'/business-intelligence',
    component: () => import('@/views/bi/BusinessIntelligence'),
    meta: {
        darkMode: 'HK-White',
        mobileMode:'mob-white',
        // description:'Boost your revenue and operations with real time data. Get insights on operational metrics, hotel trends and generate dynamic reports.',
        // img_url:'bi_banner_img.webp',
        // keywords:'Business Intelligence for Hotels, Hotel Data Insights, Advanced Analytics for Hospitality, Hotel Business Intelligence, Data Analytics for Hotels, HotelKey Analytics, Hotel Performance Metrics, Hospitality Data Analysis, Hotel BI Tools, HotelKey Business Intelligence Platform for Advanced Data Insights, Comprehensive Business Intelligence Solutions for Hotel Industry, Hotel Data Analytics Software with Performance Metrics Tracking, Advanced Data Insights Platform for Hospitality Management, Hotel Business, Intelligence Tools for Revenue Optimization, Cloud-Based Analytics Platform for Hotel Performance Monitoring, Hotel Data Analysis, Software with Predictive Analytics Capabilities, Business Intelligence Solutions for Hotel Revenue Management, HotelKey BI Dashboard for Real-Time Data hotel data, Hotel Business Intelligence Software for Decision-Making Support.',
        title:'HotelKey Business Intelligence: Advanced Data Insights for Hotels'
      },

},
{
    name:'PortfolioInsights',
    path:'/portfolio-insights',
    component: () => import('@/views/bi/PortfolioInsights'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},
{
    name:'PropertyInsights',
    path:'/property-insights',
    component: () => import('@/views/bi/PropertyInsights'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },
},
{
    name:'DynamicReport',
    path:'/dynamic-report',
    component: () => import('@/views/bi/DynamicReport'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },

},

    {
        name:'RetailKey',
        path:'/retail-key',
        component: () => import('@/views/retailkey/RetailKey'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'World-class Point-of-Sale solution to manage & run your business smoothly and efficiently. RetailKey provides a user-friendly interface, inventory management, and in-room ordering options.',
            // img_url:'register-app.webp',
            // keywords:'"Point of Sale Solution, Restaurant POS System, Retail Management Software, Hospitality POS Platform Store POS System, Boutique POS Software, Restaurant Operations Solution, Retail Business Management, Hospitality POS Technology, Retail Automation Tool, Advanced POS System for Restaurants, Streamlined Retail Management Software, Boutique POS for Small Businesses, User-Friendly Hospitality POS, Efficient Restaurant Operations Solution, Tailored Retail Automation Tool, Seamless Store POS System, Boutique POS for Sales Management, Restaurant POS for Order Processing, Retail Management Solution for Stores, User-Friendly POS Platform for Hospitality Industry and Restaurants Efficient Restaurant POS System for Streamlining Operations Innovative Retail Management Solution for Stores and Shops Hospitality POS Technology for Efficient Restaurant Operations Tailored Retail Automation Tool for Small Businesses and Boutiques Restaurant Operations Solution for Enhanced Efficiency and Customer Service Seamless Store POS System for Retail Business Management"',
            title:'RetailKey: Intelligent POS System for Hotels and Restaurants'
          },
    },
{
    name:'EventKey',
    path:'/event-key',
    component: () => import('@/views/eventkey/EventKey'),
    meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'Simplify your event management with our full serviced platform. From Weddings and parties to conferences, we host it all.',
        // img_url:'folio-management.jpg',
        // keywords:'',
        title:'EventKey: Event Management by HotelKey'
      },
},
{
    name:'TenantKey',
    path:'/tenant-key',
    component: () => import('@/views/tenantkey/TenantKey'),
    meta: {
        darkMode: 'hk-tenantKey',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''

      },
},

 
// {
//     name:'TheRestaurants',
//     path:'/retailkey',
//     component:()=> import('@/views/TheRestaurants'),
//     meta: {
//         darkMode: 'HK-Retail',
//       },
// },
{
    name:'RegisterApp',
    path:'/register-app',
    component: () => import('@/views/retailkey/RegisterApp'),
    meta: {
        darkMode: 'HK-Retail',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
      },
},
    {
        name:'ReportingAnalytics',
        path:'/reporting-analytics',
        component: () => import('@/views/retailkey/ReportingAnalytics'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'OrderManagement',
        path:'/order-management',
        component: () => import('@/views/retailkey/OrderManagement'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'OrderTakingApp',
        path:'/order-taking-app',
        component: () => import('@/views/retailkey/OrderTakingApp'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'KitchenDisplay',
        path:'/kitchen-display',
        component: () => import('@/views/retailkey/KitchenDisplay'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'EasyPayments',
        path:'/easy-payments',
        component: () => import('@/views/retailkey/EasyPayments'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'ProductManagement',
        path:'/product-management',
        component: () => import('@/views/retailkey/ProductManagement'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'HardwareIntegration',
        path:'/hardware-integration',
        component: () => import('@/views/retailkey/HardwareIntegration'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
        name:'InventoryManagement',
        path:'/inventory-management',
        component: () => import('@/views/retailkey/InventoryManagement'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'VendorsPurchaseManagement',
        path:'/vendors-purchase-management',
        component: () => import('@/views/retailkey/VendorsPurchaseManagement'),
        meta: {
            darkMode: 'HK-Retail',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'PaymentKey',
        path:'/payment-key',
        component: () => import('@/views/payment-key/PaymentKey'),
        meta: {
            darkMode: 'HK-Paymentkey',
            mobileMode:'mob-white',
            // description:'PaymentKey is your property`s single point of contact managing chargebacks, payment processing and 24*7 merchant support.',
            // img_url:'pa-banner_img.svg',
            // keywords:'"Hospitality Merchant Services, Hotel Payment Solutions, Resort Merchant Support, Hospitality Payment Processing, Lodging Payment Management, Hospitality Transaction Services, Hotel Payment Assistance, Hospitality Merchant Support, Resort Financial Services, Lodging Payment Solutions, Payment Solutions, Merchant Support, Financial Services, Payment Processing, Payment Management, Transaction Services, Payment Assistance, Merchant Support, Comprehensive Merchant Services for Hotels and Resorts, Efficient Payment Solutions for Hospitality Industry, Secure Financial Transactions with Hotel Merchant Support, Streamlined Payment Processing Services for Resorts, Professional Payment Management Assistance for Lodgings, Seamless Transaction Processing Solutions for Hospitality Businesses, Reliable Payment Assistance Services for Hotels and Resorts, Expert Merchant, Support for Financial Transactions in the Hospitality Sector, Comprehensive Payment Solutions for Hotels and Resorts, Seamless Payment Assistance for Hospitality Businesses, Secure Financial Services for Hotels and Resorts, Comprehensive Transaction Processing Solutions for Lodgings, Professional Payment Solutions for the Hospitality Industry Expert Merchant Support for Payment Management in Hotels and Resorts Efficient Financial Transactions with Payment Assistance in Hospitality Reliable Merchant Services for Hotels and Resorts Streamlined Payment Solutions for Lodgings Seamless Financial Transactions with Merchant Support in Hospitality Secure Payment Management Services for Hotels and Resorts Expert Financial Services for the Hospitality Sector"',
            title:'PaymentKey: Chargeback Support'
          },

    },
    {
        name:'IotKey',
        path:'/iotkey',
        component: () => import('@/views/iotkey/IotKey'),
        meta: {
            darkMode: 'hk-iotkey',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },

    },
    {
        name:'HiltonEnt',
        path:'/hilton',
        component: () => import('@/views/enterprises-page/HiltonEnt'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },

    {
      name:'10YearAnniversary',
      path:'/10-year-in-hospitality-technology',
      component: () => import('@/views/enterprises-page/10YearAnniversary'),
      meta: {
          darkMode: 'HK-Black',
          mobileMode:'mob-white',
          // description:'',
          // img_url:'',
          // keywords:'',
          title:''
        },
  },

    {
        name:'G6Ent',
        path:'/G6',
        component: () => import('@/views/enterprises-page/G6Ent'),
        meta: {
            darkMode: 'HK-Black hk-entpage',
            mobileMode:'mob-product',
            // description:'',
            // img_url:'',
            // keywords:'',
            title:''
          },
    },
    {
      name:'HitecEnt',
      path:'/HITEC',
      component: () => import('@/views/enterprises-page/HitecEnt'),
      meta: {
        darkMode: 'HK-Black',
        mobileMode:'mob-white',
        // description:'',
        // img_url:'',
        // keywords:'',
        title:''
        },
  },
  {
    name:'terms&conditions',
        path:'/terms&conditions',
        component: () => import('@/components/TermsConditions'),
        meta: {
            darkMode: 'HK-Black',
            mobileMode:'mob-white',
            logo_url:'hk_white_logo.svg',
            description:'',
            img_url:'pa_banner_img.svg',
            keywords:'',
            title:''
          },
        },

          {
            name:'privacy&policy',
                path:'/privacy&policy',
                component: () => import('@/components/PrivacyPolicy'),
                meta: {
                    darkMode: 'HK-Black',
                    mobileMode:'mob-white',
                    logo_url:'hk_white_logo.svg',
                    description:'',
                    img_url:'pa_banner_img.svg',
                    keywords:'',
                    title:''
                  },
                }, 

                {
                  name:'internal-sales-portal',
                  path:'/internal-sales-portal',
                  component: () => import('@/views/internal-sales-portal/InternalSalesPortal'),
                  meta: {
                      darkMode: 'HK-Black',
                      mobileMode:'mob-white',
                      logo_url:'hk_white_logo.svg',
                      description:'',
                      // img_url:'',
                    //  keywords:'',
                    //  title:''
                    },
              },

            //   {
            //     name:'popup-form',
            //     path:'/popup-form',
            //     component: () => import('@/views/about-us/PopupForm'),
            //     meta: {
            //         darkMode: 'HK-Black',
            //         mobileMode:'mob-white',
            //         logo_url:'hk_white_logo.svg',
            //         description:'',
            //         // img_url:'',
            //       //  keywords:'',
            //       //  title:''
            //       },
            // },

];





const router = createRouter({
    // mode: 'history',
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    const currentPageUrl = 'https://www.hotelkeyapp.com/#' + to.fullPath;


    // document.getElementById('app').classList.add("my-bg-class");

    document.title = to.meta.title || 'HotelKey: Next Generation Hospitality Management Platform';
    
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || 'All-in-one cloud-based system for your operations. Trusted by five enterprises and over 12,500 hotels. Property Management, Point-of-Sale, Chargeback Support, Revenue Management, Central Reservation Management, and more.');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords || 'Hotel, hotelkey, Hospitality, Hospitality Technology, esa, g6, Petronas, zendesk, Property Management System, HotelKey Platform, Platform, Housekeeping, inventory, Order Management, Order Taking App,kot, Kitchen TV Display App, Easy Payments, Hardware Integration, Product Management, Inventory Management Frontdesk, Digital Check-in, Housekeeping & Maintenance, Group management, Corporate Rates & Billing, Payments Automation, Reports, Property Administration properly Settings, Rate, Room Type, Admin, Rate Calendar, Distribution, Booking Engine, Property Website Dynamic BAR, Seasons, Special Events, Notifications & Rules, Shop Data, Automated STR Integration, Advanced Restriction Controls, Policy Management Payment Gateways, Accounting Systems, Property Local Interfaces, Property Insights, Portfolio Insights, Event Management, Payment Key, Next Generation, Market Segments, Eco-Friendly digital registration, electronic, folios, Direct Integration, Booking.com, Expedia, Point of Sale, Group Bookings, Module, Product Suite, G6 Hospitality, hotel software systems, hotel software pms, front desk hotel software, crm hotel software, affordable hotel software, hotel management software, Vacation Rental Software, Property rental software');
    document.querySelector('meta[name="currentPageUrl"]').setAttribute('content', currentPageUrl)

// Open Graph / Facebook
    // document.querySelector('meta[property="og:description"]').setAttribute('content', to.meta.description || 'All-in-one cloud-based system for your operations. Trusted by five enterprises and over 12,500 hotels. Property Management, Point-of-Sale, Chargeback Support, Revenue Management, Central Reservation Management, and more.');
    // document.querySelector('meta[property="og:keywords"]').setAttribute('content', to.meta.keywords || 'Hotel, hotelkey, Hospitality, Hospitality Technology, esa, g6, Petronas, zendesk, Property Management System, HotelKey Platform, Platform, Housekeeping, inventory, Order Management, Order Taking App,kot, Kitchen TV Display App, Easy Payments, Hardware Integration, Product Management, Inventory Management Frontdesk, Digital Check-in, Housekeeping & Maintenance, Group management, Corporate Rates & Billing, Payments Automation, Reports, Property Administration properly Settings, Rate, Room Type, Admin, Rate Calendar, Distribution, Booking Engine, Property Website Dynamic BAR, Seasons, Special Events, Notifications & Rules, Shop Data, Automated STR Integration, Advanced Restriction Controls, Policy Management Payment Gateways, Accounting Systems, Property Local Interfaces, Property Insights, Portfolio Insights, Event Management, Payment Key, Next Generation, Market Segments, Eco-Friendly digital registration, electronic, folios, Direct Integration, Booking.com, Expedia, Point of Sale, Group Bookings, Module, Product Suite, G6 Hospitality, hotel software systems, hotel software pms, front desk hotel software, crm hotel software, affordable hotel software, hotel management software, Vacation Rental Software, Property rental software');
    // document.querySelector('meta[property="og:currentPageUrl"]').setAttribute('content', currentPageUrl)

// twitter
    // document.querySelector('meta[property="twitter:description"]').setAttribute('content', to.meta.description || 'All-in-one cloud-based system for your operations. Trusted by five enterprises and over 12,500 hotels. Property Management, Point-of-Sale, Chargeback Support, Revenue Management, Central Reservation Management, and more.');
    // document.querySelector('meta[property="twitter:keywords"]').setAttribute('content', to.meta.keywords || 'Hotel, hotelkey, Hospitality, Hospitality Technology, esa, g6, Petronas, zendesk, Property Management System, HotelKey Platform, Platform, Housekeeping, inventory, Order Management, Order Taking App,kot, Kitchen TV Display App, Easy Payments, Hardware Integration, Product Management, Inventory Management Frontdesk, Digital Check-in, Housekeeping & Maintenance, Group management, Corporate Rates & Billing, Payments Automation, Reports, Property Administration properly Settings, Rate, Room Type, Admin, Rate Calendar, Distribution, Booking Engine, Property Website Dynamic BAR, Seasons, Special Events, Notifications & Rules, Shop Data, Automated STR Integration, Advanced Restriction Controls, Policy Management Payment Gateways, Accounting Systems, Property Local Interfaces, Property Insights, Portfolio Insights, Event Management, Payment Key, Next Generation, Market Segments, Eco-Friendly digital registration, electronic, folios, Direct Integration, Booking.com, Expedia, Point of Sale, Group Bookings, Module, Product Suite, G6 Hospitality, hotel software systems, hotel software pms, front desk hotel software, crm hotel software, affordable hotel software, hotel management software, Vacation Rental Software, Property rental software');
    // document.querySelector('meta[property="twitter:currentPageUrl"]').setAttribute('content', currentPageUrl)

    // const imgPath = to.meta.img_url;
    // if (imgPath !== '') {
    //     console.log('in');
    //     document.querySelector('meta[name="metaimage"]').setAttribute('content', require(`@/assets/meta_images/${to.meta.img_url}`));
    //     document.querySelector('meta[property="og:metaimage"]').setAttribute('content', require(`@/assets/meta_images/${to.meta.img_url}`));
    //     document.querySelector('meta[property="twitter:metaimage"]').setAttribute('content', require(`@/assets/meta_images/${to.meta.img_url}`));
    // } else {
    //     console.log('out');
    //     document.querySelector('meta[name="metaimage"]').setAttribute('content', require('@/assets/logo/hk-blue-squar-logo.jpg'));
    //     document.querySelector('meta[property="og:metaimage"]').setAttribute('content', require('@/assets/logo/hk-blue-squar-logo.jpg'));
    //     document.querySelector('meta[property="twitter:metaimage"]').setAttribute('content', require('@/assets/logo/hk-blue-squar-logo.jpg'));
    // }



  //   (function(h,o,t,j,a,r){
  //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //     h._hjSettings={hjid:3930405,hjsv:6};
  //     a=o.getElementsByTagName('head')[0];
  //     r=o.createElement('script');r.async=1;
  //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //     a.appendChild(r);
  // })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');





    i18n.locale = localStorage.getItem('language') || 'en'
    setTimeout(() => {
        document.getElementById('app').scrollIntoView();
        document.getElementById('app').classList.remove("my-bg-class");
    }, 3000)




  
    return next()
  })
  
  
export default router

