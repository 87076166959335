<template>
 <div class="footer-mini pt-0 pt-md-0 pt-lg-0 pb-0 pb-md-2 pb-lg-2 footer-bg" :class="[this.IsChangefooter,this.IspageName]">
      <div class="container hk-container-padding">
                <hr style="border-top: 1px solid #505F74;">
                <div class="pb-4 pt-0 pt-md-4 pt-lg-4  row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 m-text-center d-block my-auto">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item hk-cursor-pointer">
                                <router-link  :to="'/privacy&policy'" target="_blank">{{$t('footer.hk_privacy_policy')}}</router-link>
                            </li>
                            <li class="list-inline-item">|</li>
                            <li class="list-inline-item hk-cursor-pointer">
                                <router-link  :to="'/terms&conditions'" target="_blank">{{$t('footer.hk_terms_conditions')}}</router-link>
                            </li>
                            <li class="list-inline-item">|</li>
                            <li class="list-inline-item hk-cursor-pointer">
                           <router-link  :to="'/isms-policy'" target="_blank">{{$t('footer.hk_isms_policy')}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 m-text-center  text-lg-end text-md-end d-block my-auto ">
                        <!-- <h5 class="mob-footer-header-color text-uppercase fs-14">connect with us </h5> -->
                        <ul class="list-inline mb-0 hk-list-inline-item ">
                            <li class="list-inline-item fs-14 text-uppercase m-fw-600">
                                {{$t('footer.contact_with_us')}} <span> - </span>
                            </li>
                            <li class="list-inline-item hk-list-inline-item">
                                <a href="https://www.facebook.com/hotelkeyapp/" aria-label="facebook" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-square-facebook" class="" />
                                    
                                </a>
                            </li>
                            <li class="list-inline-item hk-list-inline-item">
                                <a href="https://twitter.com/HotelKeyInc"  aria-label="twitter" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-square-twitter" class="" />
                                </a>
                            </li>
                            <li class="list-inline-item hk-list-inline-item">
                                <a href="https://www.instagram.com/hotelkeyinc/"  aria-label="instagram" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-square-instagram"  class="" />
                                </a>
                            </li>
                            <li class="list-inline-item hk-list-inline-item">
                                <a href="https://www.linkedin.com/company/hotelkey/?viewAsMember=true"  aria-label="linkedin" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-linkedin" class="" />
                                </a>
                            </li>
                            <!-- <li class="list-inline-item hk-list-inline-item">
                                <a href="https://www.youtube.com/channel/UCxgi25bCnfNAhSj5-E8H0jQ?view_as=subscriber" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-square-youtube" class="text-black"/>
                                </a>
                            </li> -->
                            <li class="list-inline-item hk-list-inline-item">
                                <a href=" https://wa.me/19724397067"  aria-label="whatsapp" class="hk-a" target="_blank">
                                    <font-awesome-icon icon="fa-brands fa-square-whatsapp" class=""/>
                                </a>
                            </li>
                        </ul>
                    </div>
    
                </div>
            </div>


  
            
            <!-- Start Privacy and Policy -->
            <div class="modal fade privacy-policy " id="policy" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalLabel"
                aria-bs-modal="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content hk-modal-content">
                        <div class="modal-header model-header-border mt-3">
                            <h5 class="modal-title d-block my-auto" id="exampleModalLabel">{{$t('footer.hk_privacy_policy')}}</h5>
                            <!-- <button type="button" class="close d-block my-auto border-0 bg-transparent" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button> -->
                            <button type="button" class="hk-btn-close btn btn-sm" data-bs-dismiss="modal" aria-label="Close">
                                <img src="@/assets/logo/cross.svg" loading="lazy">
                            </button>
                        </div>
                        <div>
                            <hr>
                        </div>
                        <div class="modal-body">
                            <p> This document outlines how personal information may be collected and used (or not used) on
                                this web site, and outlines responsibilities and liabilities that correspond to such
                                collection and/or use. These terms cover this web site and its content exclusively, and do
                                not include web sites that are linked, or malicious third-parties.
                                <br>
                                <br> It is our commitment to end users to operate this web site with integrity and honesty –
                                taking the time, effort, and care to protect users and information that may be provided
                            </p>
                            <h3> Collection of Information</h3>
                            <p>
                                We only collect personal information such as names,
                                addresses, and contact information when it is voluntarily submitted by our users. The
                                information provided is only used to fulfill your specific request, and cannot be used for any
                                other purpose unless permission is given by the individual who provided the information.
                            </p>
                            <h3>Third Parties</h3>
                            <p>We may link to or display content from third parties on our web site.
                                Should a user follow such links they would be leaving this web site and no longer be governed by
                                this privacy policy. This policy and these terms apply only to content existing on this web
                                site.</p>
            
                            <h3>Cookies and Tracking Technology</h3>
            
                            <p>This site may use cookies or browser tracking
                                technologies to provide a better overall user experience. This information allows us to continue
                                modifying the site to meet end user browser types, operating systems, and frequency of visits.
                                Personal information cannot be collected by these methods unless expressly provided by the end
                                user. Aggregate cookie and tracking information may be shared with third parties.</p>
                            <br>
                            <p>This policy applies only to interactions taking place via the web site, and does not govern
                                offline interactions.</p>
                            <h3>Distribution of Information</h3>
                            <p>We may only share your personal information with government
                                agencies or other companies assisting us in fraud prevention or investigation, and when it is
                                legally required for us to do so. This information is never shared with third-party companies
                                for marketing purposes.</p>
                            <h3>Commitment to Security</h3>
                            <p>When personal information is provided to us voluntarily by end
                                users, we agree to make reasonable efforts to make such information secure. Only authorized
                                employees, agents and contractors (who operate under confidentiality agreements) will ever have
                                access to such information, and are also stipulated to make every effort to ensure security and
                                integrity of the data.</p>
                            <h3>Rights to Changes</h3>
                            <p>We reserve the rights to make changes to this document and these
                                terms without notice to end users, but will highlight any changes on this page.
                            </p>
                            <h3>HotelKey, Inc. 4100 Midway Road, Suite 2115 Carrollton, TX 75007</h3>
                            <br>
                            
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Privacy and Policy -->

            <!--start terms and condition-->
            <div class="modal fade privacy-policy " id="trust" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalLabel"
                aria-bs-modal="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content hk-modal-content">
                    <div class="modal-header model-header-border mt-3">
                        <h5 class="modal-title d-block my-auto" id="exampleModalLabel">Terms &amp; Conditions</h5>
                        <!-- <button type="button" class="close d-block my-auto border-0 bg-transparent" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button> -->
                        <button type="button" class="hk-btn-close btn btn-sm" data-bs-dismiss="modal" aria-label="Close">
                            <img src="@/assets/logo/cross.svg" loading="lazy">
                        </button>
                    </div>
                    <div>
                        <hr>
                    </div>
                    <div class="modal-body">
                        <p> By using this web site, you are agreeing to comply and be bound by the following terms of
                            service and use. Please review the following terms in their entirety and ensure their
                            comprehension before using and viewing this web site. The terms "us", "we", "our", or
                            "owners" refers to "HotelKey" and administrative operators of this web site, [url]. "You"
                            refers to the user or viewer of this web site.
                        </p>
                        <h3>Copyright</h3> 
                        <p>The content in its entirety, including text content, graphics, layouts, and
                        all source code, belong to the owners of the web site. This term is protected by intellectual
                        property rights and copyright law. Copying, redistribution, use or publication either for free
                        or for monetary gain is strictly prohibited. Some of the content on the site is the copyrighted
                        and licensed work of third parties.</p>
                        <h3>Terms of Use</h3>
                        <ul>
                            <li>- The content of this web site in its entirety is subject to change without notice. Its
                                purpose is for your general information only.</li>
                            <br>
                            <li>- No parties guarantee the accuracy, timeliness, performance, completeness, or suitability
                                of the content and information found on this site. You acknowledge that errors or
                                inaccuracies may exist, and that the owners of the site are in no way liable for any
                                such errors.</li>
                            <br>
                            <li>
                                - The owners of the site are not responsible and hold no liability for third-party content
                                that may be posted on the site by end users, or for content that is linked to from this
                                web site including other web sites.
                            </li>
                            <br>
                            <li>- The use of and viewing of information on this site is at your own risk. Any consequences
                                of use that may occur are not the liability of the web site owners.</li>
                            <br>
                            <li>- Unauthorized use of this web site or its contents may give rise to a claim for damages
                                and/or be a criminal offense enforceable by local and international law.</li>
                            <br>
                            <li>- We reserve the right to restrict access to certain areas of the web site at our own
                                discretion. If a username and password, or any other access credentials, are provided to
                                you as the end user, it is your responsibility to keep such information confidential.
                            </li>
                            <br>
                            <li>- The owners of the web site are not responsible for user-generated content, and no liable
                                for any violations that such content may constitute.</li>
                            <br>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- end terms and conditon -->
        
    <!-- cookies -->
        <div class="cookies-bg" v-if="this.cookiesDialogVisible">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="cookies-header">
                            <p>{{ $t('footer.cookies_text')}}</p>
                            <button class="btn hk-btn-cookies" @click="checkRestrickt()">{{ $t('all_hk_btn.btn_got_it')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- toast  -->
  <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 111">
  <div id="liveToast" class="toast" :class="showToast? 'show':'hide'">
    <div class="toast-header">
      <img src="@/assets/career/waving_hand.svg" class="rounded me-2 hand" alt="hand">
      <strong class="me-auto toast-color">{{$t('footer.right_side_heading')}}</strong>
      <!-- <small>11 mins ago</small> -->
      <button @click="clostToast()" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"> </button>
    </div>
    <div class="toast-body">
      <p class="mb-0 toast-color">{{ $t('footer.right_side_popup_text') }}</p>
      <!-- <p class="mb-0 toast-color">Contact the Sales Team</p> -->
      <button class="btn hk-btn-toast"  @click="goToContact()">{{$t('all_hk_btn.btn_contact_team')}}</button>
    </div>
  </div>
</div>
 </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {  },
  name: 'FooterMini',
  data(){
    return {
        cookiesDialogVisible: false,
        showToast:false,
        IsChangefooter:''
    }
  },
  computed: {
//     IsChangefooter() {
//     return this.$i18n.locale;
// },
IspageName() {
    return this.$route.name ;
}
  },
  beforeMount() {
    // console.log(this.$route.mobileMode);

    if (sessionStorage.getItem("restrictionPopup") == null) {
      this.cookiesDialogVisible = true;
      
    }
    if (sessionStorage.getItem("toastPopup") == null) {
        setTimeout(() => {
      this.showToast = true;
    }, 45000)
    }
},
  mounted() {
    
  },
  
  methods:{

   checkRestrickt() {
      if (sessionStorage.getItem("restrictionPopup") == null) {
        this.cookiesDialogVisible = false;
        sessionStorage.setItem("restrictionPopup", "true");

        window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)};
  gtag('js', new Date());
gtag('config', 'G-5NFP1NE0L9');
// old - G-Z32VVYFV0J
  
      }
    },
    goToContact() {
        if (sessionStorage.getItem("toastPopup") == null) {
            this.$router.push({path:'contact-team'});
        this.showToast = false;
        sessionStorage.setItem("toastPopup", "true");

    }
    },
    clostToast() {
        if (sessionStorage.getItem("toastPopup") == null) {
        this.showToast = false;
        sessionStorage.setItem("toastPopup", "true");
    }
    },
    getUserLocation() {
      const apiUrl = 'https://ipapi.co/json/';
      axios.get(apiUrl)
        .then(response => {
          this.IsChangefooter = response.data.country_code;
          
        })
        .catch(error => {
          console.log('Error:', error);
        });
    }
    
  },
  watch: {
    $route(to, from) {
      this.getUserLocation();
    }
  }
}

</script>
<style lang="scss" scoped>
@import '@/assets/sass/config/variables.scss';
@import '@/assets/sass/main.scss';


.footer-mini{
    background: #fff;
}
.footer-mini.IotKey{
  background: #F5F9F8;
}
.footer-mini.IT.HomePage{
  background: #FCF9F6;
}
.footer-mini.TH.HomePage{
  background: #F1F0F4;
}
.footer-mini.ES.HomePage{
  background: #FAF7EF;
}
.footer-mini.DE.HomePage{
  background: #FFFDF9;
}
.footer-mini.FR.HomePage{
  background: #F7F8FB;
}
.footer-mini.africa.HomePage{
  background: #F1F2E6E6;
}

.footer-mini{

 position: relative;
  margin-top: -1rem;
  .list-inline{
    .list-inline-item{
        color: $p-color;
        font-size: 14px;
        a{
            color: $p-color;
            font-size: 14px;
            cursor: pointer;
        }
    }
  }
}
.hand{
  width: 26px;
}
.toast-color{
  color: $main-headings;
}
.toast-header{
    font-size: 18px;
}
.hk-btn-toast{
  background: $hk-blue !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    padding: 8px !important;
    line-height: unset !important;
    letter-spacing: unset !important;
    margin-top: 5px;
}
#liveToast{
  border: unset;
    background: #fff;
}
.btn-close{
        background: transparent url('@/assets/logo/cross.svg') center/2em auto no-repeat;
        font-size: 14px;
    }
    .toast-body{
   .toast-color{
        font-size: 16px;
    }

    }   
    .toast{
        --bs-toast-max-width: 300px;
    }
.list-inline-item:not(:last-child) {
    margin-right: 0.75rem;
}
.list-inline-item.text-white a{
    opacity: 1;
}
.list-inline-item.hk-list-inline-item a{
    opacity: 1;
}
.privacy-policy{
    
    h3{
    font-size: 18px;
    font-weight: 700;
    }
    .modal-header{
        border-bottom:unset;
.hk-btn-close{
    padding: 0;
    cursor: pointer;
    img{
        width: 40px;
    }
}
    }
    .modal-body {
    max-height: 500px;
    overflow-y: auto;
   
}
.modal-content{
    padding: 0 2rem;
}

ul{
    li{
        list-style-type: none;
    }
}
    
}
.modal-dialog{
p{
    color: $main-headings;
}
}

.cookies-bg{
    width: 100%;
    background: #323232;
    // background: #323232ed;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 111;
    .cookies-header{
        display: flex;
    padding: 1rem 0rem;
    align-items: baseline;
    text-align: center;
    justify-content: center;
    .hk-btn-cookies{
// background: #6CC2B6;
// background: #259384;
background: #196155;

color: $white;
font-size: 1rem;
border-radius: 20px;
margin-left: 1rem;
font-weight: 600;
padding-right: 1.5rem;
    padding-left: 1.5rem;
}
p{
    font-size: 1rem;
    color: $white;
    margin-bottom: 0rem;
}
    }

}

.modal-title{
    font-weight: 700;
}
.btn-close:focus{
    box-shadow: unset !important;
}

@media (max-width: 575.98px){
.cookies-header{
    display: block !important;

    button{
        margin-top: 0.5rem;
    }
}
.footer-mini .list-inline .list-inline-item{
font-size: 12px;
}
.footer-mini .list-inline .list-inline-item a{
font-size: 12px;
}
.footer-mini{
    padding-left: 5px;
    padding-right: 5px;
  }
  .m-fw-600{
    font-weight: 600;
  }
}

</style>
